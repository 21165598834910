import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';

export const ArticlesWrapperSt = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  ${media.md(css`
    gap: 5rem;
    flex-direction: row;
  `)};
`;

export const ArticleImageSt = styled.img`
  object-fit: cover;
  ${media.md(css`
    height: 500px;
  `)};
`;
