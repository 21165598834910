import { Typography } from 'app/shared/components/Typography/Typography';
import Translation from 'app/shared/components/Translation/Translation';
import { DateTime } from 'luxon';
import Link from 'app/shared/components/Link/Link';
import { ArticlesWrapperSt, ArticleImageSt } from './LatestArticlesSection.css';
import { BASE_ROUTES } from 'app/hooks/useUrl/useUrl';
import { PageBuilderPageType } from '@hey-car/intl-seo-hub.page-builder';

export interface IArticleData {
  slug: string;
  heading: string;
  subheading: string;
  thumbnailImage: string;
  createdAt: string;
  type: string;
}

interface ILatestArticlesSectionProps {
  latestArticlesData: IArticleData[];
}

const LatestArticlesSection = ({
  latestArticlesData,
}: ILatestArticlesSectionProps) => {
  return (
    <>
      <Typography variant="h2">
        <Translation
          id="homepage.latestArticlesSection.title"
          defaultMessage="Últimos artículos"
        />
      </Typography>
      <ArticlesWrapperSt>
        {latestArticlesData.map((item, index) => (
          <ArticleSection item={item} key={index} />
        ))}
      </ArticlesWrapperSt>
    </>
  );
};

interface IArticleSectionProps {
  item: IArticleData;
}

const ArticleSection = ({ item }: IArticleSectionProps) => {
  const blogUrl = getArticleUrl(item);
  const blogCreatedAt = DateTime.fromISO(item.createdAt).toFormat(
    'dd MMMM yyyy',
    { locale: 'es' },
  );

  return (
    <div>
      <ArticleImageSt src={item.thumbnailImage} />
      <Link href={blogUrl}>
        <Typography variant="h2">{item.heading}</Typography>
      </Link>
      <Typography variant="body3">
        {item.subheading}{' '}
        <Link href={blogUrl}>
          [<Translation id="read" defaultMessage="Leer más" />]
        </Link>
      </Typography>
      <Typography variant="body1">
        {blogCreatedAt} | <Translation id="test" defaultMessage="Noticias" />
      </Typography>
      <hr />
    </div>
  );

  function getArticleUrl(item) {
    return `${getArticleBaseUrl(item)}/${item.slug}`;
  }

  function getArticleBaseUrl(item) {
    if (item.type === PageBuilderPageType.guides) {
      return BASE_ROUTES.guides;
    }
    return BASE_ROUTES.news;
  }
};

export { LatestArticlesSection };
