import { HtmlHeader } from 'app/shared/components/HtmlHeader/HtmlHeader';
import { metaDataBuilder } from '@hey-car/intl-seo-hub.page-builder';
import { useRouter } from 'next/router';
import { IPageData } from 'app/utils/kentico';

export const KenticoHtmlHeader = ({
  pageData,
}: Omit<IPageData, 'kenticoProjectId'>) => {
  const { title, description, noindex } = metaDataBuilder(pageData);
  const index = noindex ? 'noindex,follow' : 'index,follow';
  const { asPath } = useRouter();

  return (
    <HtmlHeader
      title={title}
      description={description}
      index={index}
      path={asPath}
    />
  );
};
