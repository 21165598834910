import { useCallback } from 'react';
import {
  BackToTopButtonSt,
  ContentWrapperSt,
  ImageBackgroundSt,
} from './BackToTopButton.css';
import Translation from 'app/shared/components/Translation/Translation';
import { ChevronTop } from '@heycar-uikit/icons';
import { SpanTypography } from 'app/shared/components/Typography/SpanTypography';

export const BackToTopButton = () => {
  const handleClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <ContentWrapperSt>
      <BackToTopButtonSt onClick={handleClick}>
        <ImageBackgroundSt>
          <ChevronTop />
        </ImageBackgroundSt>
        <SpanTypography variant="body1">
          <Translation
            id="shared.components.contentful.blocks.backToTheTopButton"
            defaultMessage="Back to top"
          />
        </SpanTypography>
      </BackToTopButtonSt>
    </ContentWrapperSt>
  );
};
