const heycarRegex = new RegExp(/(?:https?:\/\/heycar\.com\/(?:es\/)?|\/es\/)/);

export function deepReplace(
  obj: any,
  searchValue: string | RegExp = heycarRegex,
  replaceValue = '/es/',
): any {
  if (typeof obj === 'string') {
    return obj.replace(searchValue, replaceValue);
  }

  if (obj instanceof Array) {
    return obj.map(item => deepReplace(item, searchValue, replaceValue));
  }

  if (obj instanceof Object) {
    const result: any = {};
    for (const key in obj) {
      result[key] = deepReplace(obj[key], searchValue, replaceValue);
    }
    return result;
  }

  return obj; // Return the value as is if it's not a string, array, or object
}
