import { countByParams, CountByParamsResult } from 'app/apiCalls/vehicle';

import { convertMinMaxToRange } from 'app/utils/utils';
import { FilterData } from './SearchFilter';

const QUERY_NAME = 'search-filter';

interface ISearchFilterDataQueryProps {
  filters?: FilterData;
}

const DataQueryFn = ({
  filters = {},
}: ISearchFilterDataQueryProps): Promise<CountByParamsResult> => {
  const newFilters = {
    ...filters,
    nuts3: filters.province,
    radius: filters.radius ? `${filters.radius}km` : undefined,
    monthlyInstallmentRange: convertMinMaxToRange(
      filters.monthlyRateMin,
      filters.monthlyRateMax,
    ),
    priceRange: convertMinMaxToRange(filters.priceMin, filters.priceMax),
  };
  return countByParams({
    page: 1,
    filters: newFilters,
    size: 1,
    sortDirection: 'asc',
    sortBy: 'price',
  });
};

const PrefetchQueryFn = async ({ queryCache }) =>
  queryCache.prefetchQuery(
    [QUERY_NAME],
    () => DataQueryFn({}),
    DataQueryOptions,
    PrefetchQueryOptions || {},
  );

const DataQueryOptions = {
  refetchOnWindowFocus: false,
  cacheTime: 5000,
  staleTime: 5000,
};

const PrefetchQueryOptions = {
  throwOnError: true,
};

export default {
  name: QUERY_NAME,
  fn: DataQueryFn,
  prefetchFn: PrefetchQueryFn,
  options: DataQueryOptions,
};
