import { IPageData } from 'app/utils/kentico';
import KenticoPage from 'app/containers/KenticoPage/KenticoPage';
import {
  HomepageContent,
  getInitialProps as getInitialPropsHome,
} from 'app/shared/components/Homepage/HomepageContent/HomepageContent';
import { PageId } from 'app/shared/components/PageWrapper/providers/PageProvider/hooks/usePageId/usePageId';
import { IArticleData } from 'app/shared/components/Homepage/LatestArticlesSection/LatestArticlesSection';
import { FAQData } from 'app/shared/components/Homepage/FAQSection/FAQSection';
import { USPListWithImageData } from 'app/shared/components/Homepage/USPListWithImage/USPListWithImage';
import { SEOData } from 'app/shared/components/Homepage/SEOSection/SEOSection';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

interface IHomePageProps extends IPageData {
  props: {
    pageData: any;
    latestArticlesData: IArticleData[];
    faqData: FAQData;
    insuranceUSPListData: USPListWithImageData[];
    defaultUSPListData: USPListWithImageData[];
    mainHomeData: any;
    topMakesData: SEOData;
    topProvinceData: SEOData;
    topReviewsData: SEOData;
    topKm0CarsData: SEOData;
    topSecondHandCarsData: SEOData;
  };
}

const HomePage = ({ pageData, props }: IHomePageProps) => {
  const { replace, locale } = useRouter();

  useEffect(() => {
    if (locale === 'default') replace('/es');
  }, []);

  return (
    <KenticoPage pageData={pageData} pageIdPrefix={PageId.HOME_PAGE}>
      <HomepageContent {...props} />
    </KenticoPage>
  );
};

HomePage.getInitialProps = async props => {
  const { homepageData, ...rest } = await getInitialPropsHome(props);

  return {
    pageData: homepageData,
    props: rest,
  };
};

export default HomePage;
