import { ReactElement, ReactNode } from 'react';

import PageWrapper from 'app/shared/components/PageWrapper/PageWrapper';
import { WrapperSt } from 'app/containers/KenticoPage/KenticoPage.css';
import { BackToTopButton } from 'app/shared/components/BackToTopButton/BackToTopButton';
import { KenticoHtmlHeader } from 'app/shared/components/KenticoHtmlHeader/KenticoHtmlHeader';
import { IPageData } from 'app/utils/kentico';

interface IPageProps extends IPageData {
  pageIdPrefix: string;
  children: ReactNode;
}

const KenticoPage = ({
  pageData,
  pageIdPrefix,
  children,
}: Omit<IPageProps, 'kenticoProjectId'>): ReactElement => {
  const pageId = pageIdPrefix + pageData.system.codename.toUpperCase();
  return (
    <>
      <KenticoHtmlHeader pageData={pageData} />
      <PageWrapper noPageWrapper pageId={pageId}>
        <WrapperSt>
          {children}
          <BackToTopButton />
        </WrapperSt>
      </PageWrapper>
    </>
  );
};

export default KenticoPage;
