import { PageBuilder } from '@hey-car/intl-seo-hub.page-builder';
import { CenterSEOSectionSt } from './SEOSection.css';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { deepReplace } from 'app/utils/deepReplace';

export interface SEOData {
  data: IContentItem;
  projectId: string;
}

interface ISEOSectionProps {
  topMakesData: SEOData;
  topProvinceData: SEOData;
  topReviewsData: SEOData;
  topKm0CarsData: SEOData;
  topSecondHandCarsData: SEOData;
}

const SEOSection = ({ topReviewsData }: ISEOSectionProps) => {
  return (
    <>
      <SEOPopularReviewsSection
        topReviewsData={topReviewsData?.data}
        projectId={topReviewsData?.projectId}
      />
    </>
  );
};

interface ISEOPopularReviewsSectionProps {
  topReviewsData: IContentItem;
  projectId: string;
}

const SEOPopularReviewsSection = ({
  topReviewsData,
  projectId,
}: ISEOPopularReviewsSectionProps) => {
  return (
    <CenterSEOSectionSt>
      <PageBuilder page={deepReplace(topReviewsData)} projectId={projectId} />
    </CenterSEOSectionSt>
  );
};

export { SEOSection };
