import Grid from '@heycar-uikit/grid';
import {
  getHomepageData,
  getLastArticlesData,
  getTopReviewsData,
} from 'app/apiCalls/kentico';
import { FAQData } from 'app/shared/components/Homepage/FAQSection/FAQSection';
import {
  IArticleData,
  LatestArticlesSection,
} from 'app/shared/components/Homepage/LatestArticlesSection/LatestArticlesSection';
import searchFilterDataQuery from 'app/shared/components/Homepage/MainHomeSection/components/SearchFilter/SearchFilter.dataQuery';
import {
  SEOData,
  SEOSection,
} from 'app/shared/components/Homepage/SEOSection/SEOSection';
import { USPListWithImageData } from 'app/shared/components/Homepage/USPListWithImage/USPListWithImage';
import { transformToImageURL, transformToString } from 'app/utils/kentico';
import { KENTICO_OTP_PROJECT_ID } from 'next-app/app/config';
import { QueryCache } from 'react-query';
import { ByeByeBanner } from '../ByeByeBanner/ByeByeBanner';
import { ContentWrapperSt, GridColSt } from './HomepageContent.css';

const { prefetchFn: searchFilterQueryPrefetchFn } = searchFilterDataQuery;

interface IHomepageContentProps {
  latestArticlesData: IArticleData[];
  faqData: FAQData;
  insuranceUSPListData: USPListWithImageData[];
  defaultUSPListData: USPListWithImageData[];
  mainHomeData: any;
  topMakesData: SEOData;
  topProvinceData: SEOData;
  topReviewsData: SEOData;
  topKm0CarsData: SEOData;
  topSecondHandCarsData: SEOData;
}

const HomepageContent = ({
  latestArticlesData,
  ...props
}: IHomepageContentProps) => {
  return (
    <>
      <ByeByeBanner />
      <ContentWrapperSt>
        <CenterSection>
          <LatestArticlesSection latestArticlesData={latestArticlesData} />
        </CenterSection>
        <CenterSection>
          <SEOSection {...props} />
        </CenterSection>
      </ContentWrapperSt>
    </>
  );
};

const CenterSection = ({ children }) => {
  return (
    <Grid.Container>
      <Grid.Row>
        <GridColSt>{children}</GridColSt>
      </Grid.Row>
    </Grid.Container>
  );
};

interface IGetInitialProps {
  queryCache: QueryCache;
}

const getInitialProps = async ({ queryCache }: IGetInitialProps) => {
  await searchFilterQueryPrefetchFn({ queryCache });

  // homepage OTP
  const homepageData = await getHomepageData();

  // const defaultUSPListData = await getDefaultUSPListForHomepage().then(usps => {
  //   return usps.map(usp => ({
  //     heading: transformToString(usp.elements.heading),
  //     description: transformToString(usp.elements.description),
  //     image: transformToImageURL(usp.elements.image),
  //     list: transformList(usp.elements.usp_list, item => ({
  //       text: transformToString(item.elements.text, {
  //         removeParagraphs: true,
  //       }),
  //     })),
  //     cta: {
  //       text: transformToString(usp.elements.cta___label),
  //       url: transformToString(usp.elements.cta___url),
  //     },
  //   }));
  // });

  // const insuranceUSPListData = await getInsuranceUSPListForHomepage().then(
  //   usps => {
  //     return usps.map(usp => ({
  //       heading: transformToString(usp.elements.heading),
  //       description: transformToString(usp.elements.description),
  //       image: transformToImageURL(usp.elements.image),
  //       list: transformList(usp.elements.usp_list, item => ({
  //         text: transformToString(item.elements.text, {
  //           removeParagraphs: true,
  //         }),
  //       })),
  //       cta: {
  //         text: transformToString(usp.elements.cta___label),
  //         url: transformToString(usp.elements.cta___url),
  //       },
  //     }));
  //   },
  // );
  // latest articles OTP
  const latestArticlesData = await getLastArticlesData().then(articles =>
    articles.map(item => ({
      slug: transformToString(item.elements.slug),
      createdAt: transformToString(item.elements.created_at),
      thumbnailImage: transformToImageURL(item.elements.thumbnail_image),
      heading: transformToString(item.elements.heading),
      subheading: transformToString(item.elements.subheading),
      type: item.system.type,
    })),
  );

  // const faqData = await getFAQData();

  // const topMakesData = await getTopMakesData();
  // const topSecondHandCarsData = await getTopSecondHandCarsData();
  // const topKm0CarsData = await geTopKm0CarsData();
  const topReviewsData = await getTopReviewsData();
  // const topProvinceData = await getTopProvincesData();

  // const mainHomeData = await getMainHomeData().then(item => ({
  //   cta: {
  //     url: transformToString(item.elements.cta___url),
  //     align: transformToDropdownValue(item.elements.cta___align).codename,
  //     label: transformToString(item.elements.cta___label),
  //     eventName: transformToString(item.elements.cta___event_name),
  //     fullWidth: transformToBoolean(item.elements.cta___full_width),
  //   },
  //   image: {
  //     file: {
  //       url: transformToImageURL(item.elements.image),
  //     },
  //   },
  //   backgroundColor: transformToDropdownValue(item.elements.background_color)
  //     .name,
  //   searchFilter: {
  //     qaId: transformToString(item.elements.search_filter___qa_id),
  //     headline: transformToString(item.elements.search_filter___headline),
  //     footnotes: transformToString(item.elements.search_filter___footnotes),
  //   },
  //   disclaimer: transformToString(item.elements.disclaimer),
  // }));

  return {
    homepageData,
    // mainHomeData,
    // insuranceUSPListData,
    // defaultUSPListData,
    // topProvinceData: {
    //   data: topProvinceData,
    //   kenticoProjectId: KENTICO_OTP_PROJECT_ID,
    // },
    // faqData: {
    //   data: faqData,
    //   kenticoProjectId: KENTICO_OTP_PROJECT_ID,
    // },
    latestArticlesData,
    topReviewsData: {
      data: topReviewsData,
      kenticoProjectId: KENTICO_OTP_PROJECT_ID,
    },
    // topKm0CarsData: {
    //   data: topKm0CarsData,
    //   kenticoProjectId: KENTICO_OTP_PROJECT_ID,
    // },
    // topSecondHandCarsData: {
    //   data: topSecondHandCarsData,
    //   kenticoProjectId: KENTICO_OTP_PROJECT_ID,
    // },
    // topMakesData: {
    //   data: topMakesData,
    //   kenticoProjectId: KENTICO_OTP_PROJECT_ID,
    // },
  };
};

export { HomepageContent, getInitialProps };
