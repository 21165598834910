import styled from 'styled-components';

export const ImageBackgroundSt = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary500};
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const ContentWrapperSt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackToTopButtonSt = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 1rem 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary500};

  &:hover {
    ${ImageBackgroundSt} {
      background-color: ${({ theme }) => theme.colors.primary600};
    }
  }
`;
