import Grid from '@heycar-uikit/grid/dist/Grid';
import styled from 'styled-components';

export const ContentWrapperSt = styled.div`
  align-items: start;
  width: 100%;
  padding: 0;
`;

export const GridColSt = styled(Grid.Col)`
  padding: 0 !important;
`;
