import Button from '@heycar-uikit/button';
import styles from './ByeByeBanner.module.css';
import Link from 'next/link';

export const ByeByeBanner = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.layout}>
          <div className={styles.content}>
            <div className={styles.soz}>¡Lo siento!</div>
            <div className={styles.large}>
              Actualmente no es posible pedir coches en heycar.com/es
            </div>
            <div className={styles.text}>
              ¡Pero no te preocupes! Continuaremos brindándote toda la
              información relevante e importante sobre tu nuevo coche usado en
              nuestro blog. También puedes buscar un coche en línea en nuestros
              sitios web alemán o francés.
            </div>

            <div className={styles.buttons}>
              <a className={styles.button} href="/es/noticias">
                Ve a nuestro blog
              </a>
              <Link href="https://heycar.fr">
                <Button variant="outlined">Visita heycar fr</Button>
              </Link>

              <Link href="https://hey.car">
                <Button variant="outlined">Visita heycar de</Button>
              </Link>
            </div>
          </div>
          <div className={styles.image}>
            <img src="/assets/images/volvo_xc40.png" alt="Volvo XC40" />
          </div>
        </div>
      </div>
    </div>
  );
};
